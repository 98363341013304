@import '_colours.scss';
@import '_fonts.scss';

html {
    color: $grey;
    font-size: 1em;
    line-height: 1.4;
}

::-moz-selection {
    background: $contact-labels;
    text-shadow: none;
}

::selection {
    background: $contact-labels;
    text-shadow: none;
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

textarea {
    resize: vertical;
}

.browserupgrade {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}


/* ==========================================================================
   Author's custom styles
   ========================================================================== */
.background-gradient {
    background: rgb(0,98,65); /* Old browsers */
    background: -moz-linear-gradient(-45deg,  rgba(0,98,65,1) 0%, rgba(18,71,52,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg,  rgba(0,98,65,1) 0%,rgba(18,71,52,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg,  rgba(0,98,65,1) 0%,rgba(18,71,52,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#006241', endColorstr='#124734',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}
.read-more {
    color: $yellow;
    text-transform:lowercase;
    text-decoration: none;
    font-weight:700;
}
.button.read-more {
    position: absolute;
    top:2vw;
    right:20px;
    color: $white;
    background-color: $yellow;
    padding: 5px;
}
body {
    padding:0;
    margin:0;
    font-size:18px;
}
.contentwrap {
    max-width:1200px;
    margin: 0 auto;
    position:relative;
    padding: 20px;
}
header .contentwrap,
footer .contentwrap {
    padding: 0 20px;
}
img {
    border:none;
}
a.logo {
    display: inline-block;
    width: 25vw;
    height:auto;
}

/** menu generic **/
nav ul {
    list-style:none;
    padding: 0;
    margin: 0;
}
nav li {
    display: inline-block;
    box-sizing: border-box;
}
nav a {
    display: block;
    color:inherit;
    text-decoration: none;
}
header a,
footer a,
.contact-details a {
    text-decoration: none;
    color: inherit;
}
.slide-menu-active #mobile-menu span.one {
    -webkit-transform: rotate(-45deg) translate(-6px, 6px);
    transform: rotate(-45deg) translate(-6px, 6px);
}
.slide-menu-active #mobile-menu span.three {
     -webkit-transform: rotate(45deg) translate(-6px, -6px) ;
    transform: rotate(45deg) translate(-6px, -6px) ;
}
.slide-menu-active #mobile-menu span.two {
    opacity: 0;
}

/***** Slide menu ****/
.slide-menu {
    display:none;
}
.slide-menu {
	background: $green;
    width:240px;
    z-index:1000;
	position: fixed;
	height: 100%;
	top: 0;
	right: -240px;
	overflow-y:scroll;
}
.slide-menu-active .slide-menu {
    right: 0px;
}
.site-content-menu-slide {
    overflow-x:hidden;
    position:relative;
    right:0;
}
/* transition for menu slide */
.slide-menu,
.site-content-menu-slide,
#mobile-menu {
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	transition: all 0.3s ease;
}
.slide-menu h3 {
	padding: 1em;
	color:#fff;
	background:$dark-green;
	margin:0;
}
.slide-menu ul {
    padding:0;
    margin:0;
    list-style:none;
}
.slide-menu ul ul {
	background:$dark-green;
}
.slide-menu ul ul ul{
	background:$light-green;
}
.slide-menu li {
    position:relative;
    display: block;
}
.slide-menu li > ul {
    display:none;
}
.slide-menu li.expand-active > ul {
    display:block;
}
.slide-menu a {
	display: block;
	color: #fff;
	font-size: 16px;
	font-weight: 300;
	border-bottom: 1px solid $light-grey;
	padding: 18px;
	text-decoration:none;
}
.slide-menu li.has-sub {
    position: relative;
    display: block;
}
.slide-menu li.has-sub > a {
    position: relative;
    border-right: 1px solid $light-grey;
    margin-right: 50px; /* space for drop */
}
.slide-menu a:hover {
	background: $yellow;
	color: $dark-green;
	font-weight: 700;
}
.slide-menu li.has-sub > a:after {
    position: absolute;
    content: "";
    display: block;
    left: 100%;
    bottom: -1px;
    background-color: $light-grey;
    width: 50px;
    height: 1px; /* border bottom */
    z-index: 100;
}
.slide-menu ul .expand {
    position: absolute;
    top: 0;
    right: 0;
    border-left: 1px solid $light-grey;
    height: 58px;
    width: 50px;
    cursor: pointer;
}
.slide-menu ul .expand:hover {
    background-color: $yellow;
}
.slide-menu ul .expand i {
    content:"";
    display: block;
    height: 100%;
    width: 100%;
    background: url('../assets/menu-drop.svg') no-repeat center center;
    background-size: 30%;
    -webkit-transition: -webkit-transform 500ms ease;
    transition: -webkit-transform 500ms ease;
    transition: transform 500ms ease;
    transition: transform 500ms ease, -webkit-transform 500ms ease;
}
.expand-active > .expand i {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
}
/*============ Search ============*/
.t-search .highlight {
    font-size: 30px;
}
/*============ sub page============*/
.page-content {
    padding:5px;
}
.page-graphic {
    padding-top:50%;
    background-size:cover;
    background-position:center;
}
h1.page-title,
.latest-news .item-flex {
    border-bottom: 1px solid $light-grey;
    padding: 0 0 15px 0;
}

/*============ page view - column view ============*/
#page-view {
    display: flex;
    justify-content: flex-end;
}
.t-page button.full-width {
    background-image: url('../assets/full-width.svg');
}
.t-page button.full-width.clicked {
    background-image: url('../assets/full-width-clicked.svg');
}
.t-page button.two-col {
    background-image: url('../assets/two-col.svg');
}
.t-page button.two-col.clicked {
    background-image: url('../assets/two-col-clicked.svg');
}
button.full-width,
button.two-col {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    padding: 15px;
    margin: 0 5px;
    border: none;
    background-color: transparent;
}
.t-page .page-content.two-col {
    column-count: 2;
    column-gap: 40px;
}
.t-page .page-content.two-col p:first-of-type,
.t-page .page-content.two-col h1:first-of-type,
.t-page .page-content.two-col h2:first-of-type,
.t-page .page-content.two-col h3:first-of-type,
.t-page .page-content.two-col h4:first-of-type {
    margin-top:0;
}
.t-page .page-content.two-col p {
    break-inside: avoid;
    position: relative;
}
/*============ Related Content ============*/

/* Standard view / no Page Graphic */

.related-content-background {
    color: $white;
    padding: 6vw 0;
}
p.rc-subtitle {
    font-weight: 900;
    font-size:25px;
}
p.rc-summary {
    font-size: 25px;
}
p.rc-summary > span {
    font-weight: 900;
}

/* With Page Graphic */

.related-content-background.has-graphic {
    position: relative;
    background: $white;
    color: $green;
}

.related-content-graphic {
    width:100%;
    padding-top:50%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
/*============ Share  ============*/
.share {
    display: flex;
    align-items: center;
    color: $green;
}
.share > a {
    padding:20px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin-left:10px;
}
.share > a:hover {
    filter: opacity(0.5);
}
.share a.facebook {
    background-image: url('../assets/facebook.svg');
}
.share a.twitter {
    background-image: url('../assets/twitter.svg');
}
.share a.linkedin {
    background-image: url('../assets/linkedin.svg');
}
/*============ Pager  ============*/
.pager {
    display: flex;
    justify-content: center;
}
.pager a {
    padding: 10px;
    margin: 2px;
    color: $green;
    text-decoration: none;
    font-size: 20px;
}
.pager a:hover {
    font-weight: 800;
}
.pager a:active,
.pager a:focus {
    color: $light-green;
    text-decoration: underline;
}
.pager a.active {
    text-decoration: underline;
    font-weight: 800;
}

/** site links **/
.site-link {
    display: inline-block;
    width: 30px;
    height: 30px;
    margin: 5px;
    transition: all .3s ease-in-out;
}
.site-link:hover {
    filter: invert(63%) sepia(100%) saturate(566%) hue-rotate(2deg) brightness(109%) contrast(104%);
}
/* ==========================================================================
   Media Queries
   ========================================================================== */
@media only screen and (max-width: 960px) {
    a.logo {
        width: 50vw;
    }
    .slide-menu {
        display:block;
    }
    .slide-menu-active .site-content-menu-slide {
        right: 240px;
    }
    .slide-menu-active #mobile-menu {
        right: 260px;
    }
}

@media only screen and (max-width: 760px) {

    a {

        /* These are technically the same, but use both */
        overflow-wrap: break-word;
        word-wrap: break-word;

        -ms-word-break: break-all;
        /* This is the dangerous one in WebKit, as it breaks things wherever */
        word-break: break-all;
        /* Instead use this non-standard one: */
        word-break: break-word;

        /* Adds a hyphen where the word breaks, if supported (No Blink) */
        -ms-hyphens: auto;
        -moz-hyphens: auto;
        -webkit-hyphens: auto;
        hyphens: auto;

    }
    .page-body h2 {
        font-size: 1.2em;
    }
    #page-view {
        display: none;
    }
    p.rc-subtitle {
        font-size: 4.5vw;
    }
    p.rc-summary {
        font-size: 4.5vw;
    }
}
@media only screen and (max-width: 500px) {
    a.logo {
        width: 80vw;
    }
    header a.logo {
        padding-right: 70px;
    }
}
/* ==========================================================================
   Helper classes
   ========================================================================== */

.hidden {
    display: none !important;
    visibility: hidden;
}

.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}

.invisible {
    visibility: hidden;
}

.clearfix:before,
.clearfix:after {
    content: " ";
    display: table;
}

.clearfix:after {
    clear: both;
}

.clearfix {
    *zoom: 1;
}

/* ==========================================================================
   Print styles
   ========================================================================== */

@media print {
    *,
    *:before,
    *:after {
        background: transparent !important;
        color: #000 !important;
        box-shadow: none !important;
        text-shadow: none !important;
    }

    a,
    a:visited {
        text-decoration: underline;
    }

    a[href]:after {
        content: " (" attr(href) ")";
    }

    abbr[title]:after {
        content: " (" attr(title) ")";
    }

    a[href^="#"]:after,
    a[href^="javascript:"]:after {
        content: "";
    }

    pre,
    blockquote {
        border: 1px solid #999;
        page-break-inside: avoid;
    }

    thead {
        display: table-header-group;
    }

    tr,
    img {
        page-break-inside: avoid;
    }

    img {
        max-width: 100% !important;
    }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    h2,
    h3 {
        page-break-after: avoid;
    }
}

@import '_header.scss';
@import '_banners.scss';
@import '_footer.scss';
@import '_contact.scss';
@import '_forms.scss';
@import '_news.scss';