/*============ Form Generic ============*/

form {
    width: 100%;
    box-sizing: border-box;
    display:flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
form p {
    position: relative;
    margin: 5px 0;
}
label.error {
    display: block;
    font-size: 12px;
    line-height: 1;
}
input,
textarea {
    text-align: left;
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-family: $font;
    font-size: 16px;
    width: 100%;
    box-sizing: border-box;
    background-color: $white;
    border:none;
}
::placeholder,
textarea:focus,
input:focus,
input,
select,
textarea {
    font-family:$font;
}
form button {
    text-transform: uppercase;
    width: 100%;
    cursor: pointer;
}
form .button {
    color: $green;
    background-color: $yellow;
    border: 1px solid $yellow;
}

/*============ Contact Form ============*/
.enquiry {
    background-color: $yellow;
    color: $white;
}
.enquiry h1 {
    font-size: 3vw;
    text-transform: uppercase;
}
.contact-form {
    background-color: $light-grey;
}
.contact-form form {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 2.5vw 0;
}
.contact-form p {
    width: 49.5%;
}
.contact-form p.document-uploader {
    width: 100%;
}
.contact-form P:last-of-type {
    width: 100%;
    margin-bottom: -5px;
}
textarea {
    min-height: 180px;
}
.contact-form ::placeholder,
.contact-form textarea:focus,
.contact-form input:focus,
.contact-form input,
.contact-form select,
.contact-form textarea {
    color: $grey;
}
.contact-wrapper form {
    padding-top: 20px;
}
.contact-wrapper form p {
    margin: 10px 0;
}
.contact-form form .button {
    color: $light-green;
    background-color: $green;
    border: 1px solid $green;
    height: 60px;
    font-size: 20px;
    font-weight: 900;
}
.contact-form form .button:hover {
    color: $green;
    background-color: $yellow;
    border: 1px solid $yellow;
}
/*============ Mailchimp Subscribe Form ============*/
.enews {
    width:40%;
}
.enews h2 {
    color:$yellow;
    font-size: 20px;
    margin-bottom:0;
}
.enews form p.half {
    width:49%;
}
.enews form p.full {
    display: flex;
    flex-wrap: nowrap;
    width:100%;
}
.enews input.email {
    flex-shrink: 0;
    width: 70%;
}
.enews input {
    background-color: $dark-green;
}
.enews form .button {
    color: $green;
    background-color: $yellow;
    border: 1px solid $yellow;
    padding: 0 20px;
    cursor: pointer;
}
.enews form .button:hover {
    color: $green;
    background-color: $white;
    border: 1px solid $white;
}
.enews ::placeholder,
.enews textarea:focus,
.enews input:focus,
.enews input,
.enews select,
.enews textarea {
    color: $white;
}
/*============ Search Form ============*/
form.search {
width: 200px;
}
.grey form.search {
    background-color: $light-grey;
}
.search-results form.search input {
    background: $light-grey;
}
/*============ Congratulatory Message Request Form ============*/
#congrats label.float,
#congrats input {
    height: 45px;
    top: 10px;
}
fieldset {
    margin-top: 4vw;
}
fieldset .flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
fieldset .flex.flex-start {
    justify-content: flex-start;
}
legend p.heading {
    font-size: 30px;
    font-weight: 900;
    color: $green;
}
p.asabove {
    display: flex;
    align-items: center;
}
#congrats p.asabove input {
    width: 35px;
}
#congrats label.float {
    position: absolute;
    left:0;
    color: inherit;
    padding-left: 20px;
    max-width: 100%;
    box-sizing: border-box;
    transition: all 1s ease;
    z-index: 1;
}
p.js-float-wrap {
    position: relative;
    margin-top: 35px;
}
p.js-float-wrap,
#congrats p.state {
    width: 49%;
}
fieldset.recipient-details .flex .name {
    width: 32%;
}
.small,
p.js-float-wrap.small {
    width: 200px;
}
#congrats .js-float-wrap.focused label.float,
#congrats .js-float-wrap.populated label.float {
    left: 5px;
    padding-left: 0;
    top: -22px;
}
form#congrats {
    display: flex;
    flex-direction: column;
}
#congrats input,
#congrats textarea {
    text-align: left;
    padding-left: 20px;
    font-family: $font;
    color: inherit;
    font-size: 25px;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #CCC;
}
.verification legend {
    margin-bottom: 25px;
}
.button.stat-dec {
    margin: 10px 0 30px;
}
.dateof .date.small:last-of-type {
    margin-top: 20px;
}
/* Select Fields */

#congrats label.occasion,
#congrats label.state,
#congrats label.anniversary,
#congrats label.birthday {
    display: block;
    margin-top: 5px;
    margin-bottom: 0px;
}

p.occasion select,
p.state select,
p.anniversary select,
p.birthday select {
    height: 45px;
    font-size: 20px;
    color: inherit;
}
p.occasion select,
p.anniversary select,
p.birthday select,
p.date.small:first-of-type {
    margin-top: 30px;
}
p.state select {
    width: 100%;
}

p.occasion,
p.anniversary,
p.birthday {
    margin-right: 40px;
}
#congrats button.button {
    height: 100px;
    margin-top: 40px;
    font-weight: 900;
    font-size: 25px;
}
abbr {
    text-decoration: none;
}
/* ==========================================================================
   Media Queries
   ========================================================================== */
@media only screen and (max-width: 960px) {
    .enquiry h1 {
        font-size: 5vw;
    }
    .enews {
        width: 100vw;
    }
}
@media only screen and (max-width: 760px) {
    /** form **/
    .contact-details.has-map,
    .map-wrap{
        width: 100%;
        padding: 0px;
    }
    .map-wrap {
        margin-top: 20px;
    }
    #map-click {
        display: block;
    }
    .contact-form p,
    .enews form p.half {
        width: 100%;
    }
    p.js-float-wrap,
    #congrats p.state,
    fieldset.recipient-details .flex .name,
    .small,
    p.anniversary,
    p.birthday,
    p.date.small,
    p.js-float-wrap.small {
        width: 100%;
    }
    #congrats input#message_date {
        display:block;
    }
    p.occasion,
    p.anniversary,
    p.birthday {
        margin-right: 0;
    }
}