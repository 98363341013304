/*============ news index ============*/
.news-list {
    padding:0;
}
.news-list h2 {
    color: $green;
    font-size: 28px;
}
.news-list h3 {
    font-size: 18px;
}
.news-list h2,
.news-list h3,
.news-list p.teaser {
    margin-top:0;
    margin-bottom: 1vw;
}
.news-list a.item-container {
    display: block;
    text-decoration: none;
    color: inherit;
    margin-bottom: 5vw;
}
.news-list a.button {
    color: $white;
}
.news-flex {
    display: flex;
    justify-content: space-between;
}
.news-icon {
    height: 350px;
    width: 250px;
    min-width: 250px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.news-icon.fallback {
    background-image: url('../assets/fallback.jpg')
}
.news-list .content {
    width: 75%;
    height: 350px;
    margin: 0 2%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.news-list p.button {
    margin: 0;
    width: fit-content;
    padding: 0 11px;
}
.t-news #page-view {
    height: 100%;
}
.t-news #page-view button {
    margin-top: 1vw;
    margin-bottom: 1vw;
}
.t-news button.full-width {
    background-image: url('../assets/list.svg');
}
.t-news button.full-width.clicked {
    background-image: url('../assets/list-clicked.svg');
}
.t-news button.two-col {
    background-image: url('../assets/columns.svg');
}
.t-news button.two-col.clicked {
    background-image: url('../assets/columns-clicked.svg');
}
/*============ News Item ============*/


/* page heading */


.page-heading > .flex-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.page-heading > .flex-heading a {
    text-decoration: none;
    color: $light-green;
    font-size: 4vw;
    font-weight: 900;
    transition: all .5s ease;
}
.page-heading > .flex-heading a:hover {
    color: $contact-labels;
}


/* news item */


.item-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.t-news-release .page-title {
    color: $green;
    border-bottom: none;
}
.graphic-caption {
    background-color: $grey;
    padding: 10px 0;
    margin-bottom: 10px;
}
.graphic-caption p {
    margin:0 20px;
    color: $white;
}

/*============ two column news ============*/
.news-list.two-col {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}
.news-list.two-col .item.clearfix {
    width: 50%;
}
.news-list.two-col .news-flex {
    align-items: flex-end;
}
.news-list.two-col h2 {
    font-size: 23px;
}
.news-list.two-col p.teaser {
    height: 100px;
    overflow: hidden;
}
/*============ Latest News ============*/
#news-content {
    background: $light-grey;
}
#news-content h1 {
    color: $green;
}

.latest-news .item-flex {
    border-bottom: 1px solid $grey;
    margin-bottom: 30px;
}

/* ==========================================================================
   Media Queries
   ========================================================================== */
@media only screen and (max-width: 1060px) {
    .news-list.two-col .item.clearfix {
        width: 100%;
        display: block;
    }
    .t-news #page-view {
        display: none;
    }
        .news-list.two-col p.teaser {
        height: fit-content
    }
    .news-list h2,
    .news-list.two-col h2 {
        font-size: 35px;
    }
}
@media only screen and (max-width: 960px) {
    .news-list h2,
    .news-list.two-col h2 {
        font-size: 3.5vw;
    }
}

@media only screen and (max-width: 760px) {
    .news-flex {
        flex-direction: row-reverse;
    }
    .news-list .content {
        margin-left:0;
    }
}
@media only screen and (max-width: 660px) {
    .news-icon {
        display: none;
    }
    .news-list .content {
        width: 100%;
        min-height: 200px;
        height: 100%;
        margin:0;
    }
    .news-list h2,
    .news-list h3,
    .news-list p.teaser {
        margin-bottom: 3vw;
    }
    .news-list h2,
    .news-list.two-col h2 {
        font-size: 6vw;
    }
}
@media only screen and (max-width: 560px) {
    #news-content h1 {
        margin-bottom: 1vw;
    }
}