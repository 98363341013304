header.top {
    font-family: $font;
    font-size:20px;
    height: 100px;
}

.header-menu {
    position: relative;
}
.header-bar-top,
.header-menu,
.header-menu > ul,
.header-menu li.depth-0,
.header-menu li a,
.header-menu form,
.header-menu input,
.header-menu .green-phone {
    height: 100%;
    box-sizing: border-box;
}
.header-bar-top {
    background: $white;
    background-size:cover;
}
.header-menu li a {
    display:flex;
}
.header-menu,
.header-menu > ul,
.header-menu .green.phone {
    width:100%;
    display: flex;
}
.header-menu > ul {
    justify-content: flex-end;
}
.header-menu li a {
    flex:1;
    padding: 1vw;
}
.header-menu li.depth-0 span {
    width: 100%;
    text-align: center;
}
.header-menu > ul,
.header-menu li.depth-0,
.header-menu li a,
.header-menu .green.phone {
    align-items: center;
}
header.top li {
    vertical-align: top;
    /*flex-shrink: 0;*/
    transition: all .5s ease;
}
.header-menu {
    font-weight: 900;
    color: $green;
}
.header-menu input {
    font-weight: 900;
    color: $green;
    transition: all 0.3s ease;
    cursor: pointer;
}
header a.logo {
    padding: 0 1vw;
    flex-shrink: 0;
}

.header-menu li:hover,
.header-menu form:hover,
.header-menu form:hover input,
.header-menu input:focus {
    background: $light-green;
}
.header-menu li > ul {
    position:absolute;
    top: 100%;
    left:0;
    right:0;
    background:$light-green;
    background:$lg-transparent;
    color:$green;
    z-index:10000;
    text-align: center;
    display:none;
}
.header-menu li:hover > ul {
    display:block;
    height: 15vw
}
.header-menu .depth-1 a,
 .header-menu .depth-2 a {
    margin:0;
    padding: 0 30px;
}
.header-menu li.depth-1,
.header-menu li.depth-2 {
    max-width: 230px;
    height: 100%;
}
#mobile-menu {
    display: none;
    position: absolute;
    position: fixed;
    top: 90px;
    right: 20px;
    width: 30px;
    height: 30px;
    margin: 2px;
    margin-bottom: 0;
    cursor: pointer;
    z-index:100;
}
#mobile-menu > span {
    display: block;
    width: 100%;
    height: 2px;
    border-radius: 2px;
    background-color: $yellow;
    margin: 6px 0;
    transition: all 0.5s ease;
}
/*============ Green Phone  ============*/
.header-menu .green.phone {
    background-color: $green;
    color: $light-green;
    width: 21vw;
    flex-shrink: 0;
    padding: 0 0 0 1vw;
}
.header-menu .green.phone::before {
    content:'';
    background-image: url('../assets/phone.svg');
    background-size: contain;
    background-position:center;
    background-repeat: no-repeat;
    height:30px;
    width:30px;
}
.header-menu .green.phone:hover {
    background: $light-green;
    color: $dark-green;
}
.header-menu .green.phone:hover::before {
    background-image: url('../assets/phone-hover.svg');
}
.header-menu .green.phone,
.header-menu .green.phone::before,
.header-menu .green.phone:hover::before {
    transition: all .3s ease;
}
/*============ Page Heading ============*/
div.page-heading {
    color: $white;
    padding: clamp(32px, 5vw, 60px) 0;
}
.page-heading h1 {
    font-size: clamp(32px, 5vw, 50px);
}
div.grey {
    background-color: $light-grey;
    height: 5vw;
    min-height: 60px;
}
div.grey > .contentwrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 0;
    padding-bottom: 0;
    height:100%;
}
/* ==========================================================================
   Media Queries
   ========================================================================== */

@media only screen and (max-width: 960px) {
    #mobile-menu {
        display:block;
    }
    .header-menu ul,
    .header-menu form {
        display:none;
    }
    .header-menu {
        position: fixed;
        height:80px;
        width:100vw;
        background: $white;
        z-index: 20;
    }
    .header-menu .green.phone {
        margin-left: auto;
        width: 200px;
        padding-right: 30px;
    }
    header.top.sticky,
    #mobile-menu.sticky {
        position: fixed;
        top:0;
        z-index: 10000;
    }
}
@media only screen and (max-width: 760px) {
    .page-heading h1 {
        font-size: 10vw;
    }
}
@media only screen and (max-width: 500px) {
    .header-menu .green.phone {
        display: none;
    }
    #mobile-menu {
        top: 20px;
    }
    header a.logo {
        padding-right: 70px;
    }
}