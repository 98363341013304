$white:#FFF;
$yellow: #FFCD00;
$contact-labels: #80BA00;
$light-green: #C2D500;
$lg-transparent: #c2d500bf;
$green: #006241;
$dark-green: #124734;
$light-grey: #F0F0F0;
$grey: #808080;

