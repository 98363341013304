/*============ banners ============*/
.banners {
    position: relative;
}
.banners::before {
    content:'';
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    z-index:1;
    background-color: #00000063;
}
.banners::after {
    content:'';
    position: absolute;
    left:0;
    right:0;
    bottom:0;
    height:20px;
    z-index:5;
    background-color: $light-green;
}
.banner-content {
    position: absolute;
    top:0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    z-index:10;
}

.banner-content h1 {
    color: $yellow;
}
.banner-content h2 {
    color: $white;
}
.banners .banner {
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    display:block;
    background-position:center;
    background-size:cover;
}
.banner-navigation {
    display:none;
}
.banner-icon {
    background-image: url('../assets/banner_icon.png');
    height: 0;
    width: 25vw;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.banner-content .heading {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

/*============ home banner ============*/
.banners-home .banner-stage {
    padding-top:60%;
}
.banners-home .banner-icon {
    width: 44vw;
    padding-top: 60%;
}
.banners-home.banners::after {
    content:'';
    position: absolute;
    left:0;
    right:0;
    bottom:0;
    height:10vw;
    z-index:5;
    background: rgb(0,98,65); /* Old browsers */
    background: -moz-linear-gradient(-45deg,  rgba(0,98,65,1) 0%, rgba(18,71,52,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg,  rgba(0,98,65,1) 0%,rgba(18,71,52,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg,  rgba(0,98,65,1) 0%,rgba(18,71,52,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#006241', endColorstr='#124734',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
    border-bottom: 5vw solid $light-green;
}
.banners-home .banner-content .heading {
    height: unset;
}
.banners-home h1 {
    font-size: 7vw;
    margin-bottom:0;
    margin-top:12vw;
}
.banners-home h2 {
    font-size: 2.5vw;
    font-weight: 100;
    margin:0;
    margin-bottom: -4vw;
}
.banners-home .flex-column {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.banners-home .quicklinks {
    margin-left: 10px;
}
/*============ main banner ============*/
.banners-main .banner-icon {
    padding-top: 32%;
}
.banners-main .banner-stage {
    padding-top:33%;
    position:relative;
}
.banners-main .banner {
    opacity:0;
    transition: opacity 3s ease;
}
.banner.active {
    opacity:1;
}
.banners-main h1 {
    font-size: 7vw;
    margin:0;
}
.banners-main h2 {
    font-size: 2.5vw;
    font-weight: 100;
    margin:0;
}
/*============ footer banner ============*/
.banners-footer .banner-stage {
    padding-top:27%;
    position:relative;
}
.banners-footer .banner-icon {
    padding-top: 25%;
}
.banners-footer .banner-content .heading {
    margin-right: 4vw;
}
.flex-column.footer-banner-content {
    display: flex;
    height: 100%;
}
footer .quicklinks {
    display: flex;
    height: 100%;
    align-items: center;
}
.banners-footer h1 {
    font-size: clamp(32px, 5vw, 54px);
    margin:0;
}
.banners-footer h2 {
    font-size:2vw;
    font-weight: 200;
    margin:0;
}
/*============ quicklicks ============*/
.quicklinks .nav-item {
    width: 5vw;
    margin-right: 5vw;
}
.quicklinks .nav-item:last-of-type {
    margin-right:0;
}
.quicklinks .nav-item svg {
    background-color: $dark-green;
    border-radius: 50%;
    padding: 1.5vw;
    width: 100%;
}
.nav-item svg {
    fill: $white;
}
a.nav-link {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.nav-item span.title {
    color: $white;
    font-size: 2vw;
}
/* menu icon hover */

.nav-item:hover span.title{
    color: $yellow;
}
.nav-item:hover svg {
    fill: $yellow;
}
.nav-item span.title,
.quicklinks .nav-item svg {
    transition: all .5s ease;
}
.mobile-quicklinks {
    display: none;
}

/* ==========================================================================
   Media Queries
   ========================================================================== */

@media only screen and (max-width: 960px) {
    .banners h1 {
        font-size: 11vw;
    }
    .banners h2 {
        font-size: 4.5vw;
        font-weight: 500;
    }
    .quicklinks .nav-item {
        width: 10vw;
    }
    .nav-item span.title {
        font-size: 3vw;
    }
    .banners-footer .quicklinks .nav-item {
        width: 14vw;
    }
    .banners-home h1,
    .banners-home h2 {
        margin:0;
    }
    .banners-footer .quicklinks .nav-item svg {
        padding: 2vw;
    }
    .banners-footer .banner-stage,
    .banners-main .banner-stage {
        padding-top: 50%;
    }
    .banners-footer .banner-icon,
    .banners-main .banner-icon,
    .banners-home .banner-icon {
        width: 40vw;
        padding-top: 50%;
    }
    .banners-home .banner-icon {
        padding-top: 55%;
    }
    .flex-column.footer-banner-content {
        flex-direction: column;
        justify-content: space-evenly;
    }
    .banners-footer .banner-content {
        align-items: center;
    }
    .banners-footer .banner-content .heading,
    footer .quicklinks {
        height: unset;
    }
}
@media only screen and (max-width: 760px) {
    .banners-home .quicklinks,
    .banners-footer .quicklinks {
        display: none;
    }
    .banners-footer .banner-content .heading {
        margin-right: 0;
    }
    .mobile-quicklinks {
        display: flex;
        height: 30vw;
        align-items: center;
        justify-content: center;
        background: $light-grey;
        padding: 5vw;
        padding-bottom: 10vw;
    }
    footer .mobile-quicklinks {
        background: inherit;
        width: 100%;
        padding: 5vw 0 10vw 0;
    }
    .mobile-quicklinks .quicklinks .navbar-nav.flex-row {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
    }
    .mobile-quicklinks .nav-item span.title {
        color: $green;
    }
    .mobile-quicklinks .nav-item:hover span.title {
        color: $yellow;
    }
    footer .mobile-quicklinks .nav-item span.title {
        color: inherit;
    }
    .mobile-quicklinks .quicklinks .nav-item {
        width: 23vw;
        margin-right: 10vw;
    }
    .mobile-quicklinks .quicklinks .nav-item:last-of-type {
        margin:0;
    }
    .mobile-quicklinks .nav-item span.title {
        font-size: 5vw;
    }
    .mobile-quicklinks .quicklinks .nav-item svg {
        padding: 3vw;
    }
}