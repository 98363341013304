/*============ Contact ============*/
.t-contact div.grey {
    display: none;
}
.contact-wrapper .contentwrap {
    display: flex;
    max-width: 1425px;
    margin: 0;
    margin-left: auto;
    padding: 0 0 0 20px;
    justify-content: space-between;
    align-items: center;
}
.contact-wrapper .contentwrap .contact-details,
.contact-wrapper .contentwrap .map-wrap {
    margin-left: 20px;
}
.contact-details.has-map,
.map-wrap {
    display: inline-block;
    vertical-align: bottom;
    width: 38%;
    box-sizing: border-box;
}
.contact-details.has-map {
    padding-right: 10px;
}
.contact-details p {
    margin: 5px 0;
}
.map-wrap {
    position: relative;
    background-color: #d3d3d3;
    /*padding-top: 280px;*/
    height: 40vw;
}
.kms-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
}
.contact-map,
.map-click {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.contact-map {
    display: block;
}
.map-wrap > p {
    position: relative;
    z-index: 1;
    top: 5vw;
    background: $yellow;
    display: inline-block;
    padding: 1vw 10vw 1vw 1vw;
    color: $green;
    font-weight: 900;
    font-size: 20px;
}
.contact-wrapper .offices {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center
}
.contact-wrapper .flex.address,
.contact-wrapper .offices {
    padding: 0;
    align-items: flex-start;
}
.contact-wrapper .office {
    margin:0;
}
.contact-wrapper .green.label {
    color: $contact-labels;
    font-size: 22px;
    margin-bottom: 0;
    font-weight:600;
}
.contact-wrapper .green.label,
.contact-wrapper .office {
    margin-top: 2vw;
}

/* ==========================================================================
   Media Queries
   ========================================================================== */
@media only screen and (max-width: 960px) {
    .contact-wrapper .contentwrap {
        flex-direction: column;
        max-width: 1200px;
        margin: 0 auto;
        padding: 20px;
    }
    .contact-wrapper .contentwrap .contact-details,
    .contact-wrapper .contentwrap .map-wrap {
        width:100%;
        margin: 20px 0;
    }
    .map-wrap {
        height: 65vw;
    }
}
@media only screen and (max-width: 760px) {
    .offices {
        flex-direction: column;
    }
    .office {
        margin: 0;
    }
}