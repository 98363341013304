footer {
    color: $white;
}
.footer-bottom > .contentwrap {
    display: flex;
    flex-direction: column;
}
.footer-bottom .flex,
.offices {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center
}
.flex.address,
.offices {
    padding: 0;
    align-items: flex-start;
}
.office {
    margin: 0 30px;
}
.office:last-of-type {
    margin:0;
}
.yellow.label {
    color: $yellow;
    font-size: 18px;
    margin-bottom: 0;
}
.yellow.label,
.footer-contact {
    font-weight:600;
}
footer .flex {
padding-top: 3vw;
}
p.authorised {
    font-size: 14px;
}
footer a.logo {
    background-image: url('../assets/footer-logo.svg');
    background-size:contain;
    background-position: center;
    background-repeat: no-repeat;
    height: 5vw;
}
/** bykeo ***/
.bykeo {
    display: block;
    text-align: center;
    font-size: 12px;
    padding-top: 10px;
    padding-bottom: 20px;
    width: 100%;
    box-sizing: border-box;
}
.bykeo a {
    color: $white;
    text-decoration:none;
}
.bykeo img {
    vertical-align: middle;
    display: inline-block;
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
}
.bykeo a:hover img{
    transform-origin: center;
    transform: translateX(-200%) rotate(-360deg);
}
/* ==========================================================================
   Media Queries
   ========================================================================== */
@media only screen and (max-width: 960px) {
    .footer-bottom .flex, .offices {
        flex-wrap: wrap;
    }
    footer .flex.logo {
        flex-direction: column-reverse;
    }
    footer a.logo {
        height: 20vw;
    }
    .footer-bottom .flex.address {
        width: 100vw;
        justify-content: flex-start;
    }
}
@media only screen and (max-width: 760px) {
    .footer-bottom .flex.address,
    .offices {
        flex-direction: column;
    }
    .office {
        margin: 0;
    }
    .enews form p.full:last-of-type {
        flex-wrap: wrap;
    }
    .enews form p.full .email {
        width: 100%;
        margin-bottom:10px;
    }
}